/* import fonts here */
@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url('./assets/fonts/Gotham-Black.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url('./assets/fonts/Gotham-Thin.otf') format('opentype');;
  font-weight: 100;
}

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url('./assets/fonts/Gotham-Light.otf') format('opentype');;
  font-weight: 300;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 75px;
}

body{
  background-color: rgb(22, 22, 38) !important;
  color: rgb(255, 255, 255);
  font-family: 'Gotham' !important;
  font-weight: 700 !important;
  overflow-x: hidden;
  position: relative;
}

h1{
  color: rgb(255, 255, 255);
}

h3{
  color:rgba(136, 136, 136, 0.986);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav.navbar {
  padding: 18px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 18px 0;
  background-color: rgb(16, 16, 27);
}

nav.navbar a.navbar-brand {
  width: 9%;
}

.navbar .nav-link, .navbar-brand, .navbar-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.navbar .nav-link.active{
  color: rgb(140, 140, 140) !important;
}

.navbar .nav-link:hover{
  color: rgb(180, 180, 180) !important;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}


span.navbar-text {
  display: flex;
  align-items: center;
}

.social-logo {
    display: inline-block;
    margin-left: 48px;
    min-width: 150px;
}

.social-logo a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-logo a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-logo a:hover::before {
  transform: scale(1);
}

.social-logo a img {
  width: 75%;
  z-index: 1;
  transition: 0.3s ease-in-out;
  }

.social-logo a .resize {
  width: 60%
}

.social-logo a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  min-width: 700px;
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 100%;
  height: 0%;
  position: absolute;
  background-color: #ffffff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: .3s ease-in-out;
}

.navbar-text button:hover {
  color: #000000;
}

.navbar-text button{
  color: black;
}

.navbar-text button:hover::before {
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px; 
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

@media (max-width: 991px) {
nav.navbar .navbar-nav .nav-link.navbar-link {
  min-width: 220px;
  padding: 5px 10px;
  margin-left: 5px;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

.social-logo {
  display: inline-block;
  margin-left: 10px;
  min-width: 150px;
}
}

.nav-items {
  display: flex;
}


/*MAIN CSS*/
.main{
  margin-top: 0;
  padding: 200px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main .about{
  width: 80%;
  text-align: left;
}

.main .p-text{
  margin-top: 20px;
  font-size: 20px;
}

.main h2{
  font-size: 30px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.main h3, .main h4{
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  margin-top: 25px;
  color: white;
  display: block;
}

.main p {
  color: #cecece;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.main img{
  height: 325px;
}

@media screen and (max-width: 575px) {
  .main {
    padding-left: 20px;
    padding-right: 20px
  }
}

/*Main about in from left/right*/
.about{
  display: inline-block;
  animation: slideFromLeft .8s ease-out;
  visibility: visible;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.main img{
  display: inline-block;
  animation: slideFromRight .8s ease-out;
}

@keyframes slideFromRight{
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* About Me/Interests */

.aboutbox{
  width: 80%;
}

.aboutbox .align-items-center{
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 90px 0 150px 100px;
  line-height: 1.5;
}

.aboutbox .interests{
  font-size: 15px;
  font-weight: 400;
  color: #cecece;
  line-height: 2.5;
}

.aboutbox h2{
  font-size: 30px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.aboutbox .funfacts{
  font-size: 15px;
  font-weight: 400;
  color: #cecece;
  line-height: 2.5;
}

.hover img{
  margin-top: -80px;
  margin-left: -150px;
  height: 100px;
}

.funfacts img{
  width: 80px;

}

.hp img{
  visibility: hidden;
}

/* Skills */

.skill {
  padding: 10px 0 100px;
  position: relative;
}

.skill-box{
  background: black;
  border-radius: 64px;
  text-align: center;
  padding: 30px 50px;
  visibility: hidden;
}

.skill-box.in-view{
  visibility: visible;
  animation: topDown 1.2s ease-out;
}

.skill .item {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  align-items: center;
  justify-content: center;
}

.skill h2{
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.skill p{
  color: #8f8f8f;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider{
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img{
  width: 30%;
  margin: 0 auto 15px auto;
}

@keyframes topDown{
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* Projects */

.project {
  padding: 0 0 50px 0;
  position: relative;
}

.projects-box.in-view{
  visibility: visible;
  animation: topDown 1.2s ease-out;
}

.project h2{
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.project p{
  color: #8f8f8f;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.projects{
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
}

.projects-box{
  background: black;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  visibility: hidden;
}

.project .item img{
  height: auto;
  width: 220px;
  margin: 0 auto 15px auto;
}

.connect{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
}

.connectbox {
  width: 50%;
  padding: 20px;
  margin: 20px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black; 
}

.connect .nameemail{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 20px 20px 20px;
  margin-bottom: 20px;
}

.message{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.connectbox h2{
  color: white;
  font-size: 30px;
  font-weight: 700;
}

.connectbox form{
  width: 90%;
}

textarea{
  height: 150px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 16px;
}

input[type="submit"] {
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #762cae;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

input[type="submit"]:hover {
  background-color: #632592;
}

/* FOOTER */
.footer{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(16, 16, 27);
  color: white;
  padding-top: 20px;
}

.footer h2{
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.footer p{
  font-size: 12px;
}